import React from 'react';
// import Header from "../includes/Header"
import Navbar from "../includes/Navbar"
import Footer from "../includes/Footer"
import './Aboutus.css'

const Aboutus = () => {
    return (
        <>
            <header className="header-area">
                <Navbar />
                <div id="home" className="header-hero bg_cover" style={{ backgroundImage: 'url(assets/images/banner-bg.svg)' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8" style={{ height: 400, display: 'flex', justifyContent: 'center', alignContent: 'center', color: 'white', fontWeight: 'bolder', paddingTop: '100px', fontSize: '40px' }}>
                                {/* Store */}
                            </div>
                        </div>
                    </div> {/* container */}
                    <div id="particles-1" className="particles" />
                </div> {/* header hero */}
            </header>

            <div className="container">
                
                {/*====== TEAM PART START ======*/}
                <section id="team" className="team-area pt-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="section-title text-center pb-30">
                                    <div className="line m-auto" />
                                    <h3 className="title mb-2">About us</h3>
                                    <p className='about-text'>P-Signs is a Division of Prismonic Graphics and LED signs and we develop store-front LED signs. We are an electronic design firm based in Toronto  specializing in development of customer-driven customized LED signange solutions.  The hardware and software both are fully engineered and programmed in Canada. Be it logo design,  animation and graphics design or specific signange operation, we do it all!  Contact us today!</p>
                                </div> {/* section title */}
                            </div>
                        </div> {/* row */}
                        <div className="row justify-content-center">
                        </div> {/* row */}
                    </div> {/* container */}
                </section>
                {/*====== TEAM PART ENDS ======*/}

                <center>
                    <div className='email-div'>
                        <h3>Contact us at</h3>
                        <a href="mailto:info@p-signs.ca">info@p-signs.ca</a>
                        <br />
                        <a href="tel:+16476144072">+1 647 614 4072</a>
                    </div>
                </center>

                {/*====== TEAM PART START ======*/}
                <section id="team" className="team-area pt-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="section-title text-center pb-30">
                                    <div className="line m-auto" />
                                    <h3 className="title"><span>Meet Our</span> Creative Team</h3>
                                </div> {/* section title */}
                            </div>
                        </div> {/* row */}
                        <div className="row justify-content-center">
                            <div className="col-6">
                                <div className="single-team text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                                    <div className="team-image">
                                        <img src="assets/images/hsn.jpg" alt="Team" />
                                        {/* <div className="social">
                                            <ul>
                                                <li><a href="#"><i className="lni-facebook-filled" /></a></li>
                                                <li><a href="#"><i className="lni-twitter-filled" /></a></li>
                                                <li><a href="#"><i className="lni-instagram-filled" /></a></li>
                                                <li><a href="#"><i className="lni-linkedin-original" /></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="team-content">
                                        <h5 className="holder-name"><a href="#">Harpeet Singh Nagi</a></h5>
                                        <p className="text">Founder and CEO</p>
                                    </div>
                                </div> {/* single team */}
                            </div>
                            <div className="col-6">
                                <div className="single-team text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                                    <div className="team-image">
                                        <img src="assets/images/gs.jpg" alt="Team" />
                                        {/* <div className="social">
                                            <ul>
                                                <li><a href="#"><i className="lni-facebook-filled" /></a></li>
                                                <li><a href="#"><i className="lni-twitter-filled" /></a></li>
                                                <li><a href="#"><i className="lni-instagram-filled" /></a></li>
                                                <li><a href="#"><i className="lni-linkedin-original" /></a></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                    <div className="team-content">
                                        <h5 className="holder-name"><a href="#">Gursewak Singh</a></h5>
                                        <p className="text">Sr. Software Engineer</p>
                                    </div>
                                </div> {/* single team */}
                            </div>
                        </div> {/* row */}
                    </div> {/* container */}
                </section>
                {/*====== TEAM PART ENDS ======*/}
         
            </div>
            <Footer />
        </>
    )
}

export default Aboutus;