import * as React from 'react';
import { Link } from "react-router-dom";
import MainLogo from '../assets/main-logo.png';
import { } from 'react-dom';
import { useStateValue } from '../StateProvider/StateProvider';

import './Navbar.css';
import { removeCookie } from '../helpers/auth';

export default function Navbar() {
  const [navLink, setNavLink] = React.useState();
  const [store, dispatch] = useStateValue();
  
  React.useEffect(()=>{
    setNavLink(window.location.pathname);
  }, [])

  const LogoutNow = () => {
    removeCookie('user-token')
    window.location.href = '/'
  }

  return (
    <>
      {/*====== PRELOADER PART START ======*/}
      {/* <div className="preloader">
        <div className="loader">
          <div className="ytp-spinner">
            <div className="ytp-spinner-container">
              <div className="ytp-spinner-rotator">
                <div className="ytp-spinner-left">
                  <div className="ytp-spinner-circle" />
                </div>
                <div className="ytp-spinner-right">
                  <div className="ytp-spinner-circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="navbar-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="/">
                  <img src="assets/images/main-logo.png" alt="Logo" />
                  <span className="logo-text">Prismonic</span>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                </button>
                <div className="collapse navbar-collapse sub-menu-bar align-items-center justify-content-center" id="navbarSupportedContent">
                  <ul id="nav" className="navbar-nav">
                    <li className={navLink == "/graphics" ? "nav-item active" : "nav-item"}>
                      <Link className="page-scroll" to="/graphics">Graphics</Link>
                    </li>
                    <li className={navLink == "/store" ? "nav-item active" : "nav-item"}>
                      <Link className="page-scroll" to="/store">Store</Link>
                    </li>
                    <li className={navLink == "/contactus" ? "nav-item active" : "nav-item"}>
                      <Link className="page-scroll" to="/contactus">Contact us</Link>
                    </li>
                    {/* <li className={navLink == "/docs" ? "nav-item active" : "nav-item"}>
                      <Link className="page-scroll" to="/docs">Docs</Link>
                    </li> */}
                    <li className={navLink == "/orders" ? "nav-item active" : "nav-item"}>
                      <Link className="page-scroll" to="/orders">
                      {
                         store.user != '' ? 'Orders' : ''
                      }
                      </Link>
                    </li>
                  </ul>
                </div> {/* navbar collapse */}
                <div className="navbar-btn d-none d-sm-inline-block">
                  {
                    store.user == '' ?
                      <Link className="main-btn" data-scroll-nav={0} to="/login"><b>Login</b></Link> 
                      :
                      (
                        <>
                          <span className="text-white name-area text-bold">Hi, {store.user.givenName}</span>
                          <button className="btn btn-danger mx-2" onClick={LogoutNow}>Logout</button>
                        </>
                      )
                  }
                </div>
              </nav> {/* navbar */}
            </div>
          </div> {/* row */}
        </div> {/* container */}
      </div> {/* navbar area */}

    </>
  );
}
