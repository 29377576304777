import React, { useEffect, useState } from 'react';
// import Header from "../includes/Header"
import Navbar from "../includes/Navbar"
import Footer from "../includes/Footer"
import './ProductDetails.css'
import axios from 'axios';
import { useStateValue } from '../StateProvider/StateProvider';
import KEYS from '../Keys';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import { authenticate } from '../helpers/auth';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

// material bar
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const skey = "441207233054-c21cgigfl6sq45nvefas7ie67ub3gjbj.apps.googleusercontent.com";

const ProductDetails = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [store, dispatch] = useStateValue();
    const [state, setState] = useState({
        waitingState: true,
        myProduct: null,
        couponText: '',
        discountedPrice: 0,
        discountedText: '',
        currentImage: '',
        currentImageTwo: '',
        sid: '',
        qty: 1,
        haveCoupon: false,
        discount: 0,
        model_prices: [[399, 290, 10], [499, 399, 10], [499, 399, 15], [100, 100, 15]],
        features: [
            [['feature-1', 'This is Feature 1 of 1']],
            [['feature-1', 'This is Feature 1 of 2']],
            [['feature-1', 'This is Feature 1 of 3']],
            [['feature-1', 'This is Feature 1 of 4']],
        ],
        selected_model: -1
    });


    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        // get one
        // get product existing details
        axios.post(`${KEYS.NODE_URL}/api/product/getOne`)
            .then(result => {
                result.data.product.productPricing.mrp = state.model_prices[0][0]
                result.data.product.productPricing.price = state.model_prices[0][1]
                result.data.product.productPricing.discount = 100 - parseInt(state.model_prices[0][1] * 100 / state.model_prices[0][0])
                setState({
                    ...state,
                    myProduct: result.data.product,
                    waitingState: false,
                    discountedPrice: 0,
                    currentImage: "p1.jpg",
                    currentImageTwo: 'opic1.jpg'
                    // currentImage: result.data.product.coverImages[0]
                })
            }).catch(err => {
                console.log('Error :', err)
            })
    }, [])

    const onchange = (e) => {
        console.log(e.target.name);
        if (e.target.name == "productSize") {
            var myProduct = state.myProduct
            myProduct.productPricing.mrp = state.model_prices[e.target.value][0]
            myProduct.productPricing.price = state.model_prices[e.target.value][1]
            myProduct.productPricing.discount = 100 - parseInt(state.model_prices[e.target.value][1] * 100 / state.model_prices[e.target.value][0])
            setState({
                ...state,
                myProduct: myProduct,
                selected_model: e.target.value,
                discountedPrice: state.myProduct.productPricing.price * state.discount / 100,
            })
        } else {
            if(e.target.name == "qty"){
                if(e.target.value == ""){
                    setState({
                        ...state,
                        [e.target.name]: 1
                    })                    
                }else{
                    setState({
                        ...state,
                        [e.target.name]: e.target.value
                    })    
                }
            }else{
                setState({
                    ...state,
                    [e.target.name]: e.target.value
                })
            }
        }
    }

    const responseGoogle = (response) => {
        console.log(response)
        var profileObj = response.profileObj;
        console.log(profileObj)
        if (profileObj) {
            console.log("google response sent")
            // now generate our token with googleId
            axios.post(`${KEYS.NODE_URL}/api/user/auth/login`, { profileObj })
                .then(result => {
                    if (result.data.success == "1") {
                        authenticate('user', result, () => {
                            console.log("token is set successfully")
                            // now redirect
                        })
                        dispatch({
                            type: "LOGIN_USER",
                            data: result.data.user
                        })
                    } else {
                        console.error(result.data.message)
                    }
                }).catch(err => {
                    console.log('Error :', err)
                    console.log(err)
                })
        } else {
            console.log("google response is undefined")
        }
    }

    const responseGoogleErr = (response) => {
        console.error(response)
    }

    const chkCoupon = () => {
        axios.post(`${KEYS.NODE_URL}/api/coupon/check`, {
            couponText: state.couponText
        })
            .then(result => {
                if (result.data.success == "1") {
                    var discount = result.data.discount
                    var salemanId = result.data.salemanId

                    setState({
                        ...state,
                        discountedText: `You Get Discount of ${discount}%`,
                        discount: discount,
                        discountedPrice: state.myProduct.productPricing.price * discount / 100,
                        sid: salemanId
                    })
                } else {
                    var discount = 0
                    setState({
                        ...state,
                        discountedText: result.data.message,
                        discountedPrice: 0,
                    })
                }
            }).catch(err => {
                console.log('imhere...', err)
            })
    }

    const buyNow = () => {
        if (state.selected_model == -1) {
            alert("please select the model")
            return
        }
        var cp = state.myProduct
        cp.model = state.selected_model

        dispatch({
            type: "BUY",
            data: {
                currentProduct: cp,
                currentPrice: state.myProduct.productPricing.price,
                currentDiscount: state.discountedPrice,
                discountedText: state.discountedText,
                currentSalman: state.sid,
                qty: state.qty,
            }
        })
        navigate('/checkout');
    }

    const setImage = img => {
        setState({
            ...state,
            currentImage: img
        })
    }
    const setImageTwo = img => {
        setState({
            ...state,
            currentImageTwo: img
        })
    }
    const changeCouponState = (e) => {
        if (state.haveCoupon) {
            setState({
                ...state,
                discountedPrice: 0,
                discountedText: "",
                haveCoupon: !state.haveCoupon
            })
        } else {
            setState({
                ...state,
                haveCoupon: !state.haveCoupon
            })
        }

    }
    return (
        <>
            <header className="header-second mb-3">
                <Navbar />
            </header>

            {
                state.waitingState ?
                    <center className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <b><span className="mt-2">Please Wait <br /> Product Details Are Now Loading... </span></b>
                    </center> :
                    <>
                        <div className="container product-details p-2">
                            <Paper square>
                                <Tabs
                                    value={value}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                >
                                    <Tab label="Video Graphics Sign" />
                                    {/* <Tab label="Open Animation Sign" /> */}
                                </Tabs>
                                {
                                    value == 0 ? (<>
                                        <div className="vh-300 col-12 card-header my-5">
                                            <img src="assets/images/wide-image-comparison.png" alt="" />
                                            {/* <img src="https://upload.wikimedia.org/wikipedia/commons/8/82/Wide_angle_tetons.jpg" alt="" /> */}
                                        </div>
                                        <div className="row col-12 p-0 m-0">
                                            <div className="col-4 pl-0">
                                                <div className="product-image">
                                                    <TransformWrapper
                                                        initialScale={1}
                                                        initialPositionX={0}
                                                        initialPositionY={0}
                                                    >
                                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                            <React.Fragment>
                                                                <div className="tools">
                                                                    <button onClick={() => zoomIn()}>+</button>
                                                                    <button onClick={() => zoomOut()}>-</button>
                                                                    <button onClick={() => resetTransform()}>x</button>
                                                                </div>
                                                                <TransformComponent>
                                                                    <img src={'assets/images/product/' + state.currentImage} alt="" id="main-img" />
                                                                </TransformComponent>
                                                            </React.Fragment>
                                                        )}
                                                    </TransformWrapper>
                                                </div>
                                                <div className="product-images-list">
                                                    <AwesomeSlider
                                                    >
                                                        <>
                                                            <div className="sub-image">
                                                                <img src={"assets/images/product/pic2.jpg"} alt="" onClick={() => setImage("pic2.jpg")} />
                                                            </div>
                                                            <div className="sub-image">
                                                                <img src={"assets/images/product/pic3.jpg"} alt="" onClick={() => setImage("pic3.jpg")} />
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="sub-image">
                                                                <img src={"assets/images/product/pic5.jpg"} alt="" onClick={() => setImage("pic5.jpg")} />
                                                            </div>
                                                            <div className="sub-image">
                                                                <img src={"assets/images/product/p1.jpg"} alt="" onClick={() => setImage("p1.jpg")} />
                                                            </div>
                                                        </>
                                                        <>
                                                            <div className="sub-image">
                                                                <img src={"assets/images/product/p2.jpg"} alt="" onClick={() => setImage("p2.jpg")} />
                                                            </div>
                                                            <div className="sub-image">
                                                                <img src={"assets/images/product/p3.jpg"} alt="" onClick={() => setImage("p3.jpg")} />
                                                            </div>
                                                        </>
                                                    </AwesomeSlider>
                                                </div>
                                            </div>
                                            <div className="col-8 dtl">
                                                <h1>{'Digital Graphic Sign'}</h1>
                                                {/* <h1>{state.myProduct.productName}</h1> */}
                                                <hr />
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h3><span>Brand:</span> {state.myProduct.productBrand}</h3>
                                                        <ul className="rating text-yellow">
                                                            <li className="fa fa-star"></li>
                                                            <li className="fa fa-star"></li>
                                                            <li className="fa fa-star"></li>
                                                            <li className="fa fa-star"></li>
                                                            <li className="far fa-star"></li>
                                                        </ul>
                                                        <div className="pricing">
                                                            <span>${state.myProduct.productPricing.price}</span>
                                                            {/* <span style={{ textDecoration: 'line-through' }}><small>${state.myProduct.productPricing.mrp}</small></span> */}
                                                            {/* <span>{state.myProduct.productPricing.discount}% Off</span> */}
                                                        </div>
                                                        <div className="btns">
                                                            {
                                                                store.user == '' ?
                                                                    (
                                                                        <>
                                                                            <p>Login required</p>
                                                                            {/* <GoogleLogin
                                                    clientId={skey}
                                                    buttonText="Login"
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogleErr}
                                                    cookiePolicy={'single_host_origin'}
                                                /> */}
                                                                        </>
                                                                    ) :
                                                                    (
                                                                        <>
                                                                            <hr />
                                                                            <div class="coupon-input sizing-con">
                                                                                <div className="q">
                                                                                    <input type="checkbox" id="haveCoupon" onChange={changeCouponState} />
                                                                                    <label htmlFor="haveCoupon">You have Coupon?</label>
                                                                                </div>
                                                                                {
                                                                                    state.haveCoupon ?
                                                                                        <>
                                                                                            <label htmlFor="">Enter Coupon</label>
                                                                                            <div className="row c-field mx-1">
                                                                                                <input type="text" placeholder="Enter Coupon Code" value={state.couponText} name="couponText" onChange={state.haveCoupon ? onchange : null} />
                                                                                                <button className='btn btn-primary mt-2' onClick={chkCoupon}>Check</button>
                                                                                            </div>
                                                                                            {/* <span className='danger'>Expired Coupon</span> */}
                                                                                            {/* <span className='success'>Success!</span> */}
                                                                                            <span className="">{state.discountedText}</span>
                                                                                            {/* <div className="pricing">
                                                                    <span>Total Price: ${state.discountedPrice}</span>
                                                                </div> */}
                                                                                        </> : null
                                                                                }

                                                                            </div>
                                                                            <hr />
                                                                            <div className="sizing-con">
                                                                                <label htmlFor="">Select Model</label><br />
                                                                                <div className="col-12 row align-items-center">
                                                                                    <input type="radio" id='pos17' className='mb-3' name="productSize" onChange={onchange} value='3' />
                                                                                    <label htmlFor="pos17">POS-17</label>
                                                                                </div>
                                                                                <div className="col-12 row align-items-center">
                                                                                    <input type="radio" id="24" className='mb-3' name="productSize" onChange={onchange} value='0' />
                                                                                    <label htmlFor="24">PS-36</label>
                                                                                </div>
                                                                                <div className="col-12 row align-items-center">
                                                                                    <input type="radio" id='32' className='mb-3' name="productSize" onChange={onchange} value='1' />
                                                                                    <label htmlFor="32">PS-51</label>
                                                                                </div>
                                                                                <div className="col-12 row align-items-center">
                                                                                    <input type="radio" id='42' className='mb-3' name="productSize" onChange={onchange} value='2' />
                                                                                    <label htmlFor="42">PS-42</label>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="sizing-con d-none" style={{visibility: 'hidden'}}>
                                                                                <label htmlFor="">Quantity</label><br />
                                                                                <input type="number" min="1" max="10" className='mb-3' name="qty" value={state.qty} onChange={onchange} /><br />
                                                                            </div>
                                                                            <b><div className='mb-2'>Discounted : <span className="text-success text-large">C${parseInt(state.myProduct.productPricing.price - state.discountedPrice)}</span></div></b>
                                                                            
                                                                            <button className="btn btn-success buy-btn" onClick={buyNow}>Buy</button>
                                                                        </>
                                                                    )

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 features_list">
                                                    <h5>Features</h5>
                                                    {
                                                        state.selected_model == 3 ? 
                                                        <img src={'assets/images/features2.png'} alt="" /> :
                                                        <img src={'assets/images/features.png'} alt="" />
                                                    }
                                                       
                                                            {/* 
                                                            state.selected_model != -1 ?
                                                                <><h5>Features</h5>
                                                                     <ul>
                                                                        {
                                                                            state.features[state.selected_model].map(item => {
                                                                                return (
                                                                                    <li>{item[0]} <span>{item[1]}</span></li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul></> : '' */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>) :
                                        value == 1 ? (<>
                                            <div className="vh-300 col-12 card-header my-5">
                                                <img src="assets/images/OpenSignComparison2.png" alt="" />
                                                {/* <img src="https://upload.wikimedia.org/wikipedia/commons/8/82/Wide_angle_tetons.jpg" alt="" /> */}
                                            </div>
                                            <div className="row col-12 p-0 m-0">
                                                <div className="col-4 pl-0">
                                                    <div className="product-image">
                                                        <TransformWrapper
                                                            initialScale={1}
                                                            initialPositionX={0}
                                                            initialPositionY={0}
                                                        >
                                                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                                <React.Fragment>
                                                                    <div className="tools">
                                                                        <button onClick={() => zoomIn()}>+</button>
                                                                        <button onClick={() => zoomOut()}>-</button>
                                                                        <button onClick={() => resetTransform()}>x</button>
                                                                    </div>
                                                                    <TransformComponent>
                                                                        <img src={'assets/images/product/' + state.currentImageTwo} alt="" id="main-img" />
                                                                    </TransformComponent>
                                                                </React.Fragment>
                                                            )}
                                                        </TransformWrapper>
                                                    </div>
                                                    <div className="product-images-list">
                                                        <AwesomeSlider
                                                        >
                                                            <>
                                                                <div className="sub-image">
                                                                    <img src={"assets/images/product/opic1.jpg"} alt="" onClick={() => setImageTwo("opic1.jpg")} />
                                                                </div>
                                                                <div className="sub-image">
                                                                    <img src={"assets/images/product/opic4.jpg"} alt="" onClick={() => setImageTwo("opic4.jpg")} />
                                                                </div>
                                                            </>
                                                            <>
                                                                <div className="sub-image">
                                                                    <img src={"assets/images/product/opic6.jpg"} alt="" onClick={() => setImageTwo("opic6.jpg")} />
                                                                </div>
                                                            </>

                                                        </AwesomeSlider>
                                                    </div>
                                                </div>
                                                <div className="col-8 dtl">
                                                    <h1>{"open sign 24inch"}</h1>
                                                    {/* <h1>{state.myProduct.productName}</h1> */}
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <h3><span>Brand:</span> {state.myProduct.productBrand}</h3>
                                                            <ul className="rating text-yellow">
                                                                <li className="fa fa-star"></li>
                                                                <li className="fa fa-star"></li>
                                                                <li className="fa fa-star"></li>
                                                                <li className="fa fa-star"></li>
                                                                <li className="far fa-star"></li>
                                                            </ul>
                                                            <div className="pricing">
                                                                <span>${state.myProduct.productPricing.price}</span>
                                                                {/* <span style={{ textDecoration: 'line-through' }}><small>${state.myProduct.productPricing.mrp}</small></span>
                                                                <span>{state.myProduct.productPricing.discount}% Off</span> */}
                                                            </div>
                                                            <div className="btns">
                                                                {
                                                                    store.user == '' ?
                                                                        (
                                                                            <>
                                                                                <p>Login required</p>
                                                                                {/* <GoogleLogin
                                                    clientId={skey}
                                                    buttonText="Login"
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogleErr}
                                                    cookiePolicy={'single_host_origin'}
                                                /> */}
                                                                            </>
                                                                        ) :
                                                                        (
                                                                            <>
                                                                                <hr />
                                                                                <div class="coupon-input sizing-con">
                                                                                    <div className="q">
                                                                                        <input type="checkbox" id="haveCoupon" onChange={changeCouponState} />
                                                                                        <label htmlFor="haveCoupon">You have Coupon?</label>
                                                                                    </div>
                                                                                    {
                                                                                        state.haveCoupon ?
                                                                                            <>
                                                                                                <label htmlFor="">Enter Coupon</label>
                                                                                                <div className="row c-field mx-1">
                                                                                                    <input type="text" placeholder="Enter Coupon Code" value={state.couponText} name="couponText" onChange={state.haveCoupon ? onchange : null} />
                                                                                                    <button className='btn btn-primary mt-2' onClick={chkCoupon}>Check</button>
                                                                                                </div>
                                                                                                {/* <span className='danger'>Expired Coupon</span> */}
                                                                                                {/* <span className='success'>Success!</span> */}
                                                                                                <span className="">{state.discountedText}</span>
                                                                                                {/* <div className="pricing">
                                                                    <span>Total Price: ${state.discountedPrice}</span>
                                                                </div> */}
                                                                                            </> : null
                                                                                    }

                                                                                </div>
                                                                                <hr />
                                                                                <div className="sizing-con">
                                                                                    <label htmlFor="">Select Model</label><br />
                                                                                    <div className="col-12 row align-items-center">
                                                                                        <input type="radio" id="24" className='mb-3' name="productSize" onChange={onchange} />
                                                                                        <label htmlFor="24">24 inch</label>
                                                                                    </div>
                                                                                    <div className="col-12 row align-items-center">
                                                                                        <input type="radio" id='32' className='mb-3' name="productSize" onChange={onchange} />
                                                                                        <label htmlFor="32">32 inch</label>
                                                                                    </div>
                                                                                </div>
                                                                                <hr />
                                                                                <div className="sizing-con">
                                                                                    <label htmlFor="">Quantity</label><br />
                                                                                    <input type="number" min="1" max="10" className='mb-3' name="qty" value={state.qty} onChange={onchange} /><br />
                                                                                </div>
                                                                                <hr />
                                                                                <button className="btn btn-success buy-btn" onClick={buyNow}>Buy</button>
                                                                            </>
                                                                        )

                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-6 features_list">
                                                            <h5>Features</h5>
                                                            <ul>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet. Lorem, ipsum.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                                <li>Feature 1 <span>Lorem ipsum dolor sit amet.</span></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>) : null
                                }
                                <div className="pb-4"></div>
                            </Paper>
                        </div>
                        <Footer />
                    </>
            }

       </>
    )
}

export default ProductDetails;