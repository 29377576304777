// NOTE: please don't add Slash ( / )  after URL 

const KEYS = {
    // NODE_URL: 'http://localhost:8085'
    NODE_URL: 'https://prismonicsign.herokuapp.com' // latest
}

export default KEYS

// https://prismonic-test.web.app
// https://p-signs.ca
