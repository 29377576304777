import React, { useEffect, useState } from 'react';
import Home from './pages/Home';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useStateValue } from './StateProvider/StateProvider';
import { getAuthentication } from './helpers/auth';
import axios from 'axios';
import KEYS from './Keys';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// pages
import Products from './pages/Products';
import Contactus from './pages/Contactus';
import Aboutus from './pages/Aboutus';
import Store from './pages/Store';
import Auth from './components/Auth';
import ProductDetails from './pages/ProductDetails';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import Login from './pages/Login';
import Arts from './pages/Arts';
import OrderDetail from './pages/OrderDetail';
import AdminR from './pages/adminR';
import Smr from './pages/smR';

function App() {
  const [store, dispatch] = useStateValue();
  const [state, setState] = useState({
    isWait: true,
  });
  
  useEffect(() => {
    // console.log(store)
    var userToken = getAuthentication('user');
    console.log('userToken', userToken)
    if (userToken == undefined) {
      console.log('not-logined')
      // window.location.href = '/admin/login';
    }
    axios.post(`${KEYS.NODE_URL}/api/user/auth/checkToken`, {
      token: userToken
    })
      .then(result => {
        console.log(result.data)
        dispatch({
          type: "LOGIN_USER",
          data: result.data.data
        })
        setTimeout(() => {
          setState({ ...state, isWait: false });
        }, 1000);
      }).catch(err => {
        setTimeout(() => {
          setState({ ...state, isWait: false });
        }, 1000);
      })

  }, [])

  return (
    <>
      {state.isWait ?
        <center className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="spinner-grow" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <b><span className="mt-2">Please Wait</span></b>
        </center> :
        <BrowserRouter>
          <Routes>
          
            <Route path="checkout" element={<Checkout />} />
            <Route path="store" element={<ProductDetails />} />
            {/* <Route path="item" element={<ProductDetails />} /> */}
            <Route path="products" element={<Products />} />
            {/* <Route path="aboutus" element={<Aboutus />} /> */}
            <Route path="contactus" element={<Aboutus />} />
            <Route path="orders" element={<Orders />} />
            <Route path="auth" element={<Auth />} />
            <Route path="login" element={<Login />} />
            <Route path="orderDetail" element={<OrderDetail />} />
            <Route path="graphics" element={<Arts />} />
            <Route path="/admin" element={<AdminR />} />
            <Route path="/sales" element={<Smr />} />
            <Route path="/" element={<Home />} />
          </Routes> 
          <ToastContainer
            position="bottom-right"
          />
        </BrowserRouter>}

    </>
  );
}

export default App;
