
import * as React from 'react';

const Auth = () => {
    return (
        <>
        </>
    );
}

export default Auth;