import React, { useEffect, useState } from "react";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Login.css";
import axios from 'axios';
import KEYS from '../Keys';
import Navbar from "../includes/Navbar"
import { useStateValue } from "../StateProvider/StateProvider";
import { authenticate } from '../helpers/auth';
import { toast } from 'react-toastify';
import { GoogleLogin } from 'react-google-login';
import Footer from "../includes/Footer";

const skey = "441207233054-c21cgigfl6sq45nvefas7ie67ub3gjbj.apps.googleusercontent.com";

const Login = () => {
    const [store, dispatch] = useStateValue();
    const [state, setState] = useState({
        isLogin: false,
        isSignForm: true
    })

    
    const [loginState, setLoginState] = useState({
        email: '',
        password: '',
    })

    const [registerState, setRegisterState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confPassword: '',
    })


    useEffect(() => {
        
    }, [])

    const changeFormState = () => {
        setState({
            ...state,
            isSignForm: !state.isSignForm,
        })
    }

    const LoginNow = () => {
        axios.post(`${KEYS.NODE_URL}/api/user/auth/loginWithEmail`, loginState)
            .then(result => {
                // console.log(result.data);
                if(result.data.success == '1'){
                    toast.success('Login Successfully')
                    authenticate('user', result, () => {
                        console.log("token is set successfully")
                        // now redirect
                        window.location.href = '/'
                    })
                }else{
                    toast.error('Error: ' + result.data.message)
                }
            }).catch(err => {
                console.log('imhere...', err)
                console.log('imhere...', err.data)
            })
    }

    const RegisterNow = () => {
        const { password, confPassword } = registerState;
        if(password != confPassword){
            toast.success('Password and Confirm Password are not same')
            return
        }
        axios.post(`${KEYS.NODE_URL}/api/user/auth/register`, registerState)
            .then(result => {
                if(result.data.success == '1'){
                    toast.success('Login Successfully')
                    authenticate('user', result, () => {
                        console.log("token is set successfully")
                        // now redirect
                        // redirect to redirection url
                        window.location.href = '/'
                    })
                }else{
                    toast.error('Error: ' + result.data.message)
                }
            }).catch(err => {
                console.log('imhere...', err)
                console.log('imhere...', err.data)
            })
    }

    const onChangeLogin = e => {
        setLoginState({
            ...loginState,
            [e.target.name]: e.target.value
        })
    }
    
    const onChangeRegister = e => {
        setRegisterState({
            ...registerState,
            [e.target.name]: e.target.value
        })
    }
    

    
    const responseGoogle = (response) => {
        console.log(response)
        var profileObj = response.profileObj;
        console.log(profileObj)
        if(profileObj){
            console.log("google response sent")
            // now generate our token with googleId
            axios.post(`${KEYS.NODE_URL}/api/user/auth/login`, { profileObj })
                .then(result => {
                    if(result.data.success == "1"){
                        authenticate('user', result, () => {
                            console.log("token is set successfully")
                            // now redirect
                        })
                        // dispatch({
                        //     type: "LOGIN_USER",
                        //     data: result.data.user
                        // })
                        window.location.href = '/'
                    }else{
                        console.error(result.data.message)
                    }
                }).catch(err => {
                    console.log('Error :', err)
                    console.log(err)
                })
            }else{
                console.log("google response is undefined")
            }
    }

    const responseGoogleErr = (response) => {
        console.error(response)
    }


    return (
        <>
            <div className="header-second">
                <Navbar />
            </div>

       
            <div className="container py-5">
                <div className="row d-flex justify-content-center h-100">
                    <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                        <div className="col-12 mb-4 d-flex align-items-center justify-content-center">
                            <button className={!state.isSignForm ? 'btn mr-3' : 'btn btn-success mr-3'} onClick={changeFormState}>Login</button>
                            <button className={state.isSignForm ? 'btn' : 'btn btn-success'} onClick={changeFormState}>Register</button>
                        </div>
                        {
                            state.isSignForm ? (<form>
                                <h3 className="mb-3 col-12 text-center">Login Here</h3>
                                {/* Email input */}
                                <div className="form-outline mb-4">
                                    <input type="text" id="loginEmail" name="email" className="form-control form-control-lg" value={loginState.email} onChange={onChangeLogin}/>
                                    <label className="form-label" htmlFor="loginEmail">Email</label>
                                </div>
                                {/* Password input */}
                                <div className="form-outline mb-0">
                                    <input type="password" id="loginPass" name="password" className="form-control form-control-lg"  value={loginState.password} onChange={onChangeLogin}/>
                                    <label className="form-label" htmlFor="loginPass">Password</label>
                                </div>
                                <div className="col-12">
                                    {/* Checkbox */}
                                    <a className="float-right mb-3" href="#!">Forgot password?</a>
                                </div>
                                {/* Submit button */}
                                <button type="button" className="btn btn-primary btn-lg btn-block" onClick={LoginNow}>Login</button>
                                <div className="divider d-flex align-items-center my-4 col-12">
                                    <p className="text-center fw-bold mb-0 text-muted col-12">OR</p>
                                </div>
                            </form>) :
                            (
                                <form>
                                    <h3 className="mb-3 col-12 text-center">Register Here</h3>
                                    {/* Email input */}
                                    <div className="form-outline mb-4">
                                        <input type="text" id="firstName" name="firstName" className="form-control form-control-lg" value={registerState.firstName} onChange={onChangeRegister}/>
                                        <label className="form-label" htmlFor="firstName">First Name</label>
                                    </div>
                                    <div className="form-outline mb-4">
                                        <input type="text" id="lastName" name="lastName" className="form-control form-control-lg" value={registerState.lastName} onChange={onChangeRegister}/>
                                        <label className="form-label" htmlFor="lastName">Last Name</label>
                                    </div>
                                    <div className="form-outline mb-4">
                                        <input type="email" id="registerEmail" name="email" className="form-control form-control-lg" value={registerState.email} onChange={onChangeRegister}/>
                                        <label className="form-label" htmlFor="registerEmail">Email</label>
                                    </div>
                                    {/* Password input */}
                                    <div className="form-outline mb-4">
                                        <input type="password" id="registerPass" name="password" className="form-control form-control-lg"  value={registerState.password} onChange={onChangeRegister}/>
                                        <label className="form-label" htmlFor="registerPass">Password</label>
                                    </div>
                                    <div className="form-outline mb-0">
                                        <input type="password" id="registerConfPass" name="confPassword" className="form-control form-control-lg"  value={registerState.confPassword} onChange={onChangeRegister}/>
                                        <label className="form-label" htmlFor="registerConfPass">Confirm Password</label>
                                    </div>
                                    <div className="col-12">
                                        {/* Checkbox */}
                                        <a className="float-right mb-3" href="#!">Forgot password?</a>
                                    </div>
                                    {/* Submit button */}
                                    <button type="button" className="btn btn-primary btn-lg btn-block" onClick={RegisterNow}>Register</button>
                                    <div className="divider d-flex align-items-center my-4 col-12">
                                        <p className="text-center fw-bold mb-0 text-muted col-12">OR</p>
                                    </div>
                                </form>
                            )
                        }
                        <div id="gBtn">
                            <GoogleLogin
                                clientId={skey}
                                buttonText="Continue with google"
                                onSuccess={responseGoogle}
                                onFailure={responseGoogleErr}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                        {/* <a className="btn btn-primary btn-lg btn-block" style={{ backgroundColor: '#3b5998' }} href="#!" role="button">
                            <i className="fab fa-google mx-2" />Continue with Google
                        </a> */}
                    </div>
                </div>
            </div>
      <Footer />

        </>
    );
};

export default Login;