import React from 'react';
// import Header from "../includes/Header"
import Navbar from "../includes/Navbar"
import Footer from "../includes/Footer"
import './Products.css'

const Products = () => {
    return (
        <>
            <header className="header-area">
                <Navbar />
                <div id="home" className="header-hero bg_cover" style={{ backgroundImage: 'url(assets/images/banner-bg.svg)' }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8" style={{ height: 400, display: 'flex', justifyContent: 'center', alignContent: 'center', color: 'white', fontWeight: 'bolder', paddingTop: '100px', fontSize: '40px' }}>
                                {/* Store */}
                            </div>
                        </div>
                    </div> {/* container */}
                    <div id="particles-1" className="particles" />
                </div> {/* header hero */}
            </header>

            <div className="container">
            <section id='productsSection'>
                <div className="productsSection-header">
                    <h1>our products</h1>
                    <h4>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt, vitae?</h4>
                </div>
                <div className="row col-12">
                <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <div className="product-grid">
                            <div className="product-image">
                                    <img className="pic-1" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                <a href="#" className="image" >
                                    <img className="pic-2" src="https://m.media-amazon.com/images/I/61YVqHdFRxL._AC_SL1322_.jpg" />
                                </a>
                                <ul className="product-links">
                                    {/* <li><a href="#"><i className="fa fa-shopping-cart"></i></a></li> */}
                                    {/* <li><a href="#"><i className="far fa-heart"></i></a></li> */}
                                    <li><a href="#"><i className="fa fa-share"></i></a></li>
                                    {/* <li><a href="#"><i className="fa fa-search"></i></a></li> */}
                                </ul>
                            </div>
                            <div className="product-content text-center">
                                <h3 className="title"><a href="#">Timo screening</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            </div>

  
            <Footer />

        </>
    )
}

export default Products;