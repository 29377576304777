import React, { useEffect, useState } from "react";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Orders.css";
import axios from 'axios';
import KEYS from '../Keys';
import Navbar from "../includes/Navbar"
import { useStateValue } from "../StateProvider/StateProvider";
import { getAuthentication } from "../helpers/auth";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Footer from "../includes/Footer";

const Orders = () => {
    const navigate = useNavigate()
    const [store, dispatch] = useStateValue();
    const [state, setState] = useState({
        orders: [],
        isWait: true,
    })


    useEffect(() => {
        // pending here...
        var userToken = getAuthentication('user');
        axios.post(`${KEYS.NODE_URL}/api/order/all`, {
            token: userToken
          })
            .then(async (result) => {
                console.log(result);
                setState({
                    ...state,
                    orders: result.data.orders,
                    isWait: false
                })
            }).catch(err => {
              console.log('error: ', err)
              setState({
                ...state,
                isWait: false
            })
            })
    }, [])
    
    return (
        <>
            <div className="header-second">
                <Navbar />
            </div>

            <div className="container billingDtl col-8">
                <h3>My Orders</h3>
                <hr />
                <div className="orders">
                    {
                        state.isWait ? <>
                            <center className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <b><span className="mt-2">Please Wait <br /> Orders are now Loading... </span></b>
                    </center>
                        </> :
                        state.orders.length == 0 ? <>
                            <center>
                                <h3 className="text-danger mt-5">You haven't Any Order</h3>
                                {/* <img width="100px" src="https://media.istockphoto.com/photos/empty-shopping-cart-on-white-background-picture-id475748214" alt="" /> */}
                            </center>
                        </> :
                        state.orders.map((item, key) => (
                            <div className="orderItem">
                                {/* <div className="img">
                                    <img src={item[1].coverImages[0]} alt="" />
                                </div> */}
                                <div className="dtl">
                                    <div>
                                        <span className="name">Digital Graphic Sign</span>
                                        {/* <span className="name">{item[1].productName}</span> */}
                                        <span><b>Price:</b> ${Math.round(item[0].productInfo.p_price.price) - Math.round(item[0].productInfo.p_discount)}</span>
                                        <span><b>Qty:</b> {item[0].productInfo.p_qty}</span>
                                    </div>
                                    <div>
                                        <span className="name"><b>Total Price:</b> ${Math.round((item[0].productInfo.p_price.price - item[0].productInfo.p_discount)* item[0].productInfo.p_qty * 1.13)}</span>
                                        <span><b>Date:</b> {item[0].basicInfo.date + " " + item[0].basicInfo.time}</span>
                                        <button className="btn btn-primary" onClick={()=>{
                                            navigate(`/orderDetail?id=${item[0]._id}`)
                                        }}><span>More</span></button>
                                        {/* <span><b>Status:</b> {"pending"}</span> */}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <Footer />
     
        </>
    );
};

export default Orders;