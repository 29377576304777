import { useEffect } from "react";

const AdminR = () => {
    useEffect(() => {
        window.location.href = 'https://admin-prismonic-test.web.app/'
    }, [])

    return (
        <>
       
        </>
    )
}

export default AdminR;