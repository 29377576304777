import './Footer.css'

const Footer = () => {
    return (
        <>
            <footer id="footer" className="pt-100">
                <div className="footer-box">Design and Developed by&nbsp;<a href="http://Prismonic.ca" target="_blank" rel="noopener noreferrer">Prismonic</a></div>
            </footer>
            <a href="#" className="back-to-top"><i className="lni-chevron-up" /></a>
        </>
    );
}

export default Footer;