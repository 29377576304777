import React, { useEffect, useState } from "react";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Arts.css";
import axios from 'axios';
import KEYS from '../Keys';
import Navbar from "../includes/Navbar"
import { useStateValue } from "../StateProvider/StateProvider";
import { getAuthentication } from "../helpers/auth";
import Gallery from 'react-matrix-gallery'
import Footer from "../includes/Footer";

const Arts = () => {
    const [store, dispatch] = useStateValue();
    const [state, setState] = useState({
        isWait: false,
        base_name: "https://p-signs.ca/"
    })

    useEffect(() => {
    }, [])
   
    return (
        <>
            <div className="header-second">
                <Navbar />
            </div>

            <div className="container billingDtl gallary col-8">
                <h3>Our Graphics</h3>
                <hr />
                
                
                {/* <Gallery layout={[
                        [1/4, 1/4, 1/4, 1/4], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/4, 1/4], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/2, 1/2], 
                        [1/4, 1/4, 1/2, 1/2], 
                    ]}> */}
                    <div className="gallary-main">
                    <img src={`assets/images/graphics/1.gif`}/>
                    <img src={`assets/images/graphics/1qq.gif`}/>
                    <img src={`assets/images/graphics/1qq1.gif`}/>
                    <img src={`assets/images/graphics/1qqw.gif`}/>
                    <img src={`assets/images/graphics/1w.gif`}/>
                    <img src={`assets/images/graphics/2.gif`}/>
                    <img src={`assets/images/graphics/3.gif`}/>
                    <img src={`assets/images/graphics/3ww.gif`}/>
                    <img src={`assets/images/graphics/5.gif`}/>
                    <img src={`assets/images/graphics/6.gif`}/>
                    <img src={`assets/images/graphics/7.gif`}/>
                    <img src={`assets/images/graphics/9.gif`}/>
                    <img src={`assets/images/graphics/10.gif`}/>
                    <img src={`assets/images/graphics/10a.gif`}/>
                    <img src={`assets/images/graphics/11.gif`}/>
                    <img src={`assets/images/graphics/13.gif`}/>
                    <img src={`assets/images/graphics/114.gif`}/>
                    <img src={`assets/images/graphics/115.gif`}/>
                    <img src={`assets/images/graphics/117.gif`}/>
                    <img src={`assets/images/graphics/118.gif`}/>
                    <img src={`assets/images/graphics/119.gif`}/>
                    <img src={`assets/images/graphics/120.gif`}/>
                    <img src={`assets/images/graphics/121.gif`}/>
                    <img src={`assets/images/graphics/122.gif`}/>
                    <img src={`assets/images/graphics/123.gif`}/>
                    <img src={`assets/images/graphics/124.gif`}/>
                    <img src={`assets/images/graphics/125.gif`}/>
                    <img src={`assets/images/graphics/126.gif`}/>
                    <img src={`assets/images/graphics/127.gif`}/>
                    <img src={`assets/images/graphics/128.gif`}/>
                    <img src={`assets/images/graphics/129.gif`}/>
                    <img src={`assets/images/graphics/130.gif`}/>
                    <img src={`assets/images/graphics/131.gif`}/>
                    <img src={`assets/images/graphics/132.gif`}/>
                    <img src={`assets/images/graphics/133.gif`}/>
                    <img src={`assets/images/graphics/134.gif`}/>
                    <img src={`assets/images/graphics/135.gif`}/>
                    <img src={`assets/images/graphics/136.gif`}/>
                    <img src={`assets/images/graphics/137.gif`}/>
                    <img src={`assets/images/graphics/138.gif`}/>
                    <img src={`assets/images/graphics/139.gif`}/>
                    <img src={`assets/images/graphics/116.gif`}/>
                {/* </Gallery> */}
                </div>
            </div>
            <Footer />
            
        </>
    );
};

export default Arts;