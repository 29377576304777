import React from 'react';
// import Header from "../includes/Header"
import Navbar from "../includes/Navbar"
import Footer from "../includes/Footer"
import { Link } from 'react-router-dom';

import './Home.css';

const Home = () => {
    return (
        <>
            <div>
                {/*[if IE]>
    <p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p>
  <![endif]*/}
                {/*====== PRELOADER PART ENDS ======*/}
                {/*====== HEADER PART START ======*/}
                <header className="header-area">
                    <Navbar />
                    <div id="home" className="header-hero bg_cover" style={{ backgroundImage: 'url(assets/images/banner-bg.svg)' }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="header-hero-content text-center">
                                        <h3 className="header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0s">Business LED signs</h3>
                                        {/* <h2 className="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0s">powered by new motion graphics</h2> */}
                                        <p className="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0s">powered by new motion graphics</p>
                                        {/* <a href="#" className="main-btn wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="1.1s">Get Started</a> */}
                                    </div> {/* header hero content */}
                                </div>
                            </div> {/* row */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1s">
                                        <img src="assets/images/Png1.png" alt="hero" />
                                        {/* <img src="assets/images/header-hero.png" alt="hero" /> */}
                                    </div> {/* header hero image */}
                                </div>
                            </div> {/* row */}
                        </div> {/* container */}
                        <div id="particles-1" className="particles" />
                    </div> {/* header hero */}
                </header>
                {/*====== HEADER PART ENDS ======*/}
                {/*====== BRAMD PART START ======*/}
                {/* here is brand area */}
                {/*====== BRAMD PART ENDS ======*/}
                {/*====== SERVICES PART START ======*/}
                <section id="features" className="services-area pt-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="section-title text-center pb-40">
                                    <div className="line m-auto" />
                                    <h3 className="title">Our Customers Location
                                    {/* <span> Comes with everything you need to get started!</span> */}
                                    </h3>
                                </div> {/* section title */}
                            </div>
                        </div> {/* row */}
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-7 col-sm-8">
                                <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <div className="services-icon">
                                        {/* <img className="shape" src="assets/images/services-shape.svg" alt="shape" /> */}
                                        <img src="assets/images/s1.png" alt="shape" />
                                        {/* <i className="lni-baloon" /> */}
                                    </div>
                                    <div className="services-content mt-30">
                                        <h4 className="services-title">Krypto Nails</h4>
                                        <p className="text mt-2">2313A Yonge St, <br /> Toronto, ON M4P 2C6</p>
                                        {/* <a className="more" href="#">Learn More <i className="lni-chevron-right" /></a> */}
                                    </div>
                                </div> {/* single services */}
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-8">
                                <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <div className="services-icon">
                                        <img src="assets/images/s2.png" alt="shape" />
                                        {/* <img className="shape-1" src="assets/images/services-shape-2.svg" alt="shape" />
                                        <i className="lni-cog" /> */}
                                    </div>
                                    <div className="services-content mt-30">
                                    <h4 className="services-title">Gianni's Pasta + Italian Sandwich</h4>
                                        <p className="text mt-2">877 Pape Ave, <br /> East York, ON M4K 3T9</p>
                                        {/* <a className="more" href="#">Learn More <i className="lni-chevron-right" /></a> */}
                                    </div>
                                </div> {/* single services */}
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-8">
                                <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <div className="services-icon">
                                        <img src="assets/images/s3.png" alt="shape" />
                                        {/* <img className="shape" src="assets/images/services-shape.svg" alt="shape" />
                                        <img className="shape-1" src="assets/images/services-shape-3.svg" alt="shape" />
                                        <i className="lni-bolt-alt" /> */}
                                    </div>
                                    <div className="services-content mt-30">
                                    <h4 className="services-title">Auto Fix Center</h4>
                                        <p className="text mt-2">1010 Brock Rd, <br /> Pickering, ON L1W 3E5</p>
                                        {/* <a className="more" href="#">Learn More <i className="lni-chevron-right" /></a> */}
                                    </div>
                                </div> {/* single services */}
                            </div>
                        </div> {/* row */}
                    </div> {/* container */}
                </section>
                {/*====== SERVICES PART ENDS ======*/}
                {/*====== ABOUT PART START ======*/}
                <section id="about" className="about-area pt-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center justify-content-end pr-5">
                                <div className="about-content mt-50 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <div className="section-title">
                                        <div className="line right" />
                                        <h3 className="title right">Content Designining support</h3>
                                    </div> {/* section title */}
                                    <p className="text right">Focus more on your business, with our pixel level graphics work provided by our professional designer which brings new look in your business.</p>
                                    {/* <a href="#" className="main-btn">Lorem</a> */}
                                </div> {/* about content */}
                            </div>
                            <div className="col-lg-6">
                                <div className="about-image text-center mt-50 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s" >
                                    <img src="assets/images/png4.png" alt="about" />
                                </div> {/* about image */}
                            </div>
                        </div> {/* row */}
                    </div> {/* container */}
                    <div className="about-shape-1">
                        <img src="assets/images/about-shape-1.svg" alt="shape" />
                    </div>
                </section>
                {/*====== ABOUT PART ENDS ======*/}
                {/*====== ABOUT PART START ======*/}
                <section className="about-area pt-70">
                    <div className="about-shape-2">
                        <img src="assets/images/about-shape-2.svg" alt="shape" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-last d-flex align-items-center">
                                <div className="about-content mt-50 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <div className="section-title">
                                        <div className="line" />
                                        <h3 className="title">Change scrolling text and update graphics via app</h3>
                                    </div> {/* section title */}
                                    <p className="text">Control sign via smart phone app, change your custom scrolling message anytime and update sign with available new picture graphics via Wi-Fi internet</p>
                                    {/* <a href="#" className="main-btn">Lorem</a> */}
                                </div> {/* about content */}
                            </div>
                            <div className="col-lg-6 order-lg-first pr-5">
                                <div className="about-image text-center mt-50 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <img src="assets/images/png3.png" alt="about" />
                                </div> {/* about image */}
                            </div>
                        </div> {/* row */}
                    </div> {/* container */}
                </section>
                <section id="about" className="about-area pt-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center justify-content-end pr-5">
                                <div className="about-content mt-50 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    <div className="section-title right">
                                        <div className="line right" />
                                        <h3 className="title right">Glimpse of Video Graphics Sign</h3>
                                    </div> {/* section title */}
                                    <p className="text right">Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima repellat repellendus placeat quidem error numquam doloremque nisi rem. Corrupti officia sequi tenetur repellendus optio consectetur laborum exercitationem, vitae natus at, impedit dolore.</p>
                                    {/* <a href="#" className="main-btn">Lorem</a> */}
                                </div> {/* about content */}
                            </div>
                            <div className="col-lg-6">
                                <div className="video-content mt-50 wow fadeIn" data-wow-duration="0.1s" data-wow-delay="0s">
                                    {/* <img className="dots" src="assets/images/dots.svg" alt="dots" /> */}
                                    <div className="video-wrapper">     
                                        <div className="video-image">
                                            <img src="assets/images/video.png" alt="video" />
                                        </div>
                                        <div className="video-icon">
                                            <a href="https://youtu.be/vZs5aVtC--o" className="video-popup"><i className="lni-play" /></a>
                                        </div>
                                    </div> {/* video wrapper */}
                                </div> {/* video content */}
                            </div>
                        </div> {/* row */}
                    </div> {/* container */}
                    <div className="about-shape-1">
                        <img src="assets/images/about-shape-1.svg" alt="shape" />
                    </div>
                </section>
                {/*====== FOOTER PART START ======*/}
            </div>
            );
            <Footer />
        </>
    );
}

export default Home;