import React, { useState, useEffect } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./Checkout.css";
import axios from 'axios';
import KEYS from '../Keys';
import Navbar from "../includes/Navbar"
import { useStateValue } from "../StateProvider/StateProvider";
import SweetAlert from 'react-bootstrap-sweetalert';
import { getAuthentication } from "../helpers/auth";
import { useNavigate } from "react-router-dom";
import Gallery from 'react-matrix-gallery'

// material bar
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { toast } from "react-toastify";
import Footer from "../includes/Footer";

const Checkout = () => {
  const [store, dispatch] = useStateValue();
  const [state, setState] = useState({
    step: 1,
    b_firstname: '',
    b_lastname: '',
    b_email: store.user.email,
    b_phone: '',
    b_street: '',
    b_city: '',
    b_state: '',
    b_zip: '',
    s_firstname: '',
    s_lastname: '',
    s_email: '',
    s_phone: '',
    s_street: '',
    s_city: '',
    s_state: '',
    s_zip: '',
    s_type: ''
  })
  // const stripe = loadStripe(
  //   "pk_test_51KXuS8Bzdikrori2m81e3S2LoMGhs989NpEvAHeAkuyV4Q9hM6mnwxN75dpopxIFabECcvNxVaTwb75JwH2AYZZg00h6cQ9qE3"
  // );

  // sir live keys
  const stripe = loadStripe(
    "pk_live_51KXuS8Bzdikrori2TQwaMPepuEWDWi9scubuWr1b3PdtBRrJr6y6EN0pEV5g8iYDtVNqqFgFoWIctReroYVSzdou000WrQ9YmM"
  );

  useEffect(() => {
    if (store.buy == '') {
      window.location.href = '/store'
    }
    window.scrollTo(0, 0)

    dispatch({
      type: "CHANGE_STEP",
      data: 1
    })
  }, [])

  const onChangeStep = step => {
    window.scrollTo(0, 0)
    if (step >= 2) {
      if (
        state.b_firstname.length == '' ||
        state.b_lastname.length == '' ||
        state.b_email.length == '' ||
        state.b_phone.length == '' ||
        state.b_street.length == '' ||
        state.b_city.length == '' ||
        state.b_state.length == '' ||
        state.b_zip.length == ''
      ) {
        alert("Please Fill the Billing Address")
        return
      }
    }
    dispatch({
      type: "CHANGE_STEP",
      data: step
    })
    // setState({ ...state, step })
    if (step == 2) {
      var totalInformation = {
        b_firstname: state.b_firstname,
        b_lastname: state.b_lastname,
        b_email: state.b_email,
        b_phone: state.b_phone,
        b_street: state.b_street,
        b_city: state.b_city,
        b_state: state.b_state,
        b_zip: state.b_zip,
        s_firstname: state.s_firstname,
        s_lastname: state.s_lastname,
        s_email: state.s_email,
        s_phone: state.s_phone,
        s_street: state.s_street,
        s_city: state.s_city,
        s_state: state.s_state,
        s_zip: state.s_zip,
        s_type: state.s_type,
      }
      dispatch({
        type: "SET_TOTALINFO",
        data: totalInformation
      })
    }
    if (step == 3) {
      // HERE DO WORK
    }
  }

  const onChangeVal = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  return (
    <>
      <div className="header-second">
        <Navbar />
      </div>

      <div className="container billingDtl col-8">

        <div className="step-progress col-12">
          <div className="row">
            <div onClick={() => onChangeStep(1)} className={store.step == 1 ? "step-1 active" : "step-1"}>
              <span>Address</span>
            </div>
            <div onClick={() => onChangeStep(2)} className={store.step == 2 ? "step-2 active" : "step-2"}>
              <span>Customize</span>
            </div>
            <div onClick={() => onChangeStep(3)} className={store.step == 3 ? "step-3 active" : "step-3"}>
              <span>Payment</span>
            </div>
            {/* <div className={store.step == 3 ? "step-3 active" : "step-3"}>
              <span>Final</span>
            </div> */}
          </div>
        </div>

        {
          store.step == 1 ? (
            <>
              {/* here is form 1 */}
              <h3 className="my-3">Billing Information</h3>
              <hr />
              {/* <h5>User Information</h5> */}
              <div className="row col-12">
                <div class="form-floating mb-3 col-6">
                  <label for="b_firstname">First Name</label>
                  <input type="text" class="form-control" id="b_firstname" name="b_firstname" value={state.b_firstname} onChange={onChangeVal} placeholder="" />
                </div>
                <div className="form-floating mb-3 col-6">
                  <label for="b_lastname">Last Name</label>
                  <input type="text" class="form-control" id="b_lastname" name="b_lastname" value={state.b_lastname} onChange={onChangeVal} placeholder="" />
                </div>
              </div>
              <div className="row col-12">
                <div class="form-floating mb-3 col-6">
                  <label for="b_email">Email</label>
                  <input type="text" class="form-control" id="b_email" name="b_email" value={state.b_email} readOnly placeholder="" style={{ 'cursor': 'not-allowed' }} />
                </div>
                <div class="form-floating mb-3 col-6">
                  <label for="b_phone">Phone</label>
                  <input type="text" class="form-control" id="b_phone" name="b_phone" value={state.b_phone} onChange={onChangeVal} placeholder="" />
                </div>
              </div>
              <div className="row col-12">
                <div class="form-floating mb-3 col-12">
                  <label for="b_street">Street/Town</label>
                  <input type="text" class="form-control" id="b_street" name="b_street" value={state.b_street} onChange={onChangeVal} placeholder="" />
                </div>
              </div>
              <div className="row col-12">
                <div class="form-floating mb-3 col-4">
                  <label for="b_city">City</label>
                  <input type="text" class="form-control" id="b_city" name="b_city" value={state.b_city} onChange={onChangeVal} placeholder="" />
                </div>
                <div class="form-floating mb-3 col-4">
                  <label for="b_state">Province</label>
                  <input type="text" class="form-control" id="b_state" name="b_state" value={state.b_state} onChange={onChangeVal} placeholder="" />
                </div>
                <div class="form-floating mb-3 col-4">
                  <label for="b_zip">Zip</label>
                  <input type="text" class="form-control" id="b_zip" name="b_zip" value={state.b_zip} onChange={onChangeVal} placeholder="" />
                </div>
              </div>
              <div className="hide">
                <br />
                <h3 className="my-3">Shipping Information</h3>
                <hr />
                <div className="row col-12">
                  <div class="form-floating mb-3 col-6">
                    <label for="s_firstname">First Name</label>
                    <input type="text" class="form-control" id="s_firstname" name="s_firstname" value={state.s_firstname} onChange={onChangeVal} placeholder="" />
                  </div>
                  <div class="form-floating mb-3 col-6">
                    <label for="s_lastname">Last Name</label>
                    <input type="text" class="form-control" id="s_lastname" name="s_lastname" value={state.s_lastname} onChange={onChangeVal} placeholder="" />
                  </div>
                </div>
                <div className="row col-12">
                  <div class="form-floating mb-3 col-6">
                    <label for="s_email">Email</label>
                    <input type="text" class="form-control" id="s_email" name="s_email" value={state.s_email} onChange={onChangeVal} placeholder="" />
                  </div>
                  <div class="form-floating mb-3 col-6">
                    <label for="s_phone">Phone</label>
                    <input type="text" class="form-control" id="s_phone" name="s_phone" value={state.s_phone} onChange={onChangeVal} placeholder="" />
                  </div>
                </div>
                <div className="row col-12">
                  <div class="form-floating mb-3 col-12">
                    <label for="s_street">Street/Town</label>
                    <input type="text" class="form-control" id="s_street" name="s_street" value={state.s_street} onChange={onChangeVal} placeholder="" />
                  </div>
                </div>
                <div className="row col-12">
                  <div class="form-floating mb-3 col-4">
                    <label for="s_city">City</label>
                    <input type="text" class="form-control" id="s_city" name="s_city" value={state.s_city} onChange={onChangeVal} placeholder="" />
                  </div>
                  <div class="form-floating mb-3 col-4">
                    <label for="s_state">State</label>
                    <input type="text" class="form-control" id="s_state" name="s_state" value={state.s_state} onChange={onChangeVal} placeholder="" />
                  </div>
                  <div class="form-floating mb-3 col-4">
                    <label for="s_zip">Zip</label>
                    <input type="text" class="form-control" id="s_zip" name="s_zip" value={state.s_zip} onChange={onChangeVal} placeholder="" />
                  </div>
                </div>
              </div>
              <br />
              <h4>Pickup Address</h4>
              <hr />
              <address>
                <pre>
                  11 Brunel Crt, Unit#2516, <br />
                  Toronto ON, Canada <br />
                  M5V 3Y3 <br />
                </pre>
              </address>
              {/* <div class="form-floating col-4">
                <input className="mx-3" type="radio" id="95type" name="s_type" value="0" onChange={onChangeVal} />
                <label for="95type">9am - 5pm</label>
              </div>
              <div class="form-floating mb-3 col-4">
                <input className="mx-3" type="radio" id="alltype" name="s_type" value="1" onChange={onChangeVal} />
                <label for="alltype">All Day</label>
              </div> */}
              <hr />
              {/* here is next button */}
              <button className="btn btn-primary mt-4 col-2 align-self-end" onClick={() => onChangeStep(2)}>Save & Next</button>            </>
          )
            : store.step == 2 ?
              <>
                <TransitionManageForm />
              </>

              : store.step == 3 ?
                (
                  <Elements stripe={stripe}>
                    <div className="row outer-div">
                      <div className="invoice-data card col-5">
                        <div className="card-header">
                          <h5>Summary</h5>
                        </div>
                        <div className="card-body">
                          <h4 className="mb-4">LED SIGN ({
                            store.buy.currentProduct.model == '0' ? 'PS-36' :
                              store.buy.currentProduct.model == '1' ? 'PS-51' :
                                store.buy.currentProduct.model == '2' ? 'PS-42' : 
                                store.buy.currentProduct.model == '3' ? 'POS-17' : ''
                          })
                          </h4>
                          <div className="row">
                            <div className="left">Quantity</div>
                            <div className="right">{store.buy.qty} Piece</div>
                          </div>
                          <div className="row">
                            <div className="left">Unit Price</div>
                            <div className="right">C${store.buy.currentProduct.productPricing.price}</div>
                          </div>
                          <div className="row">
                            <div className="left"><b>SubTotal (Quantity * Unit Price)</b></div>
                            <div className="right">C${store.buy.qty * store.buy.currentProduct.productPricing.price}</div>
                          </div>
                          {
                            Math.round(store.buy.currentDiscount * store.buy.qty) != 0 ?
                            <div className="row">
                              <div className="left">Coupon Discount</div>
                              <div className="right text-danger"> - C${(store.buy.currentDiscount * store.buy.qty).toFixed(2)}</div>
                            </div> : ''
                          }
                          <div className="row">
                            <div className="left">HST Amount (13%)</div>
                            <div className="right"> + C${(0.13 * store.buy.qty * (store.buy.currentProduct.productPricing.price - store.buy.currentDiscount)).toFixed(2)}</div>
                          </div>
                          <div className="row">
                            <div className="left"><b>Total Price</b></div>
                            <div className="right"><div className="imp">C${(((0.13 * store.buy.qty * (store.buy.currentProduct.productPricing.price - store.buy.currentDiscount))) + ((store.buy.qty * (store.buy.currentProduct.productPricing.price - store.buy.currentDiscount)))).toFixed(2)}</div></div>
                          </div>
                        </div>
                        <div className="card-body">
                          <h4 className="mb-4">Address
                          </h4>
                          <div className="row">
                            <div className="left"><b>Billing Address</b></div>
                            <div className="right">{`${store.totalInformation.b_street}, ${store.totalInformation.b_city}, ${store.totalInformation.b_state} - ${store.totalInformation.b_zip}`}</div>
                          </div>
                          <div className="row">
                            <div className="left"><b>Pickup</b></div>
                            <div className="right">11 Brunel Crt, Toronto ON, Canada - M5V 3Y3</div>
                          </div>
                        </div>
                      </div>
                      <div className="card col-5">
                        <div className="card-header">
                          Payment Here
                        </div>
                        <div className="card-body m-0 p-0">
                          <CheckoutForm />
                        </div>
                      </div>
                    </div>
                  </Elements>
                )
                : null
        }
      </div>
      <Footer />
    </>
  );
};

function CheckoutForm() {
  let navigate = useNavigate();
  const [store, dispatch] = useStateValue();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const payMoney = async (e) => {
    e.preventDefault();

    if(isPaymentLoading == true){
      return
    }else{
      setPaymentLoading(true);
    }

    if (!stripe || !elements) {
      return;
    }
    
    // uploadBusinessInfo
    var Tstate = store.fullTransitionState

    const formData = new FormData();
    var userToken = getAuthentication('user');
    formData.append('facebook', Tstate.fb_link)
    formData.append('instagram', Tstate.insta_link)
    formData.append('website', Tstate.web_link)
    formData.append('txt', Tstate.txt)

    Object.values(Tstate.images).forEach(file => {
      formData.append("uploadImages", file);
    });

    Object.values(Tstate.videos).forEach(file => {
      formData.append("uploadImages", file);
    });

    axios.post(`${KEYS.NODE_URL}/api/order/uploadBusinessInfo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(result => {
        console.log('result data uploaded : ', result)

        dispatch({
          type: "SET_BUSINESS_ID",
          data: result.data.bInfo._id
        });
      
        var addressInfo = store.totalInformation;
        var productInfo = store.buy
        var business_info_id = result.data.bInfo._id
        
        // get date/time
        let date_ob = new Date();
        let order_id = date_ob.valueOf();
        let date = ("0" + date_ob.getDate()).slice(-2);
        let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        let year = date_ob.getFullYear();
        let hours = date_ob.getHours();
        let minutes = date_ob.getMinutes();
        let seconds = date_ob.getSeconds();
        var nowDate = year + "-" + month + "-" + date;
        var nowTime = hours + ":" + minutes;

        var userToken = getAuthentication('user');
        axios.post(`${KEYS.NODE_URL}/api/product/stripe/charge`, {
          orderInfo: {
            addressInfo, productInfo, business_info_id, nowDate, nowTime
          },
          token: userToken
        })
          .then(async (result) => {
            const orderId = result.data.order_id.toString();
            const clientSecret = result.data.client_secret.toString();
            console.log('clientSecret ' + clientSecret)
            const paymentResult = await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: "Gursewak singh",
                },
              },
            });
            setPaymentLoading(false);
            if (paymentResult.error) {
              alert(paymentResult.error.message);
              
              // delete last order and delete last businessdetail
              axios.post(`${KEYS.NODE_URL}/api/product/delete/order`, {
                oid: orderId,
                bid: business_info_id
              }).then(result => {
                console.log(result)
              }).catch(err => {

              })
            } else {
              if (paymentResult.paymentIntent.status === "succeeded") {
                console.log("paymentResult")
                console.log(paymentResult)
                // now 
                axios.post(`${KEYS.NODE_URL}/api/product/sendInvoice`, {
                  orderInfo: {
                    orderId,
                    paymentId: paymentResult.paymentIntent.id,
                    payment_status: "1"
                  },
                  token: userToken
                }).then((result) => {
                  navigate("/orders")
                  console.log(result)
                  alert("Success!");

                }).catch((err) => {
                  navigate("/orders")
                  console.log(err)
                })


                // navigate(`/orders`);
                // window.location.href = '/orders'
              }
            }
          }).catch(async err => {
            setPaymentLoading(false);

            console.log('error: ', err)
            const paymentResult = await stripe.confirmCardPayment(false, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: "Gursewak singh",
                },
              },
            });
            if (paymentResult.error) {
              alert(paymentResult.error.message);
            }
          })

      }).catch(err => {
        console.log('imhere...', err.response)
      })




  };

  return (
    <div
      style={{
        padding: "3rem",
      }}
    >
      <div
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <form
          style={{
            display: "block",
            width: "100%",
          }}
          onSubmit={!isPaymentLoading ? payMoney : null}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardElement
              className="card"
              options={{
                style: {
                  base: {
                    backgroundColor: "white"
                  }
                },
              }}
            />
            <button
              className="pay-button"
              disabled={isPaymentLoading}
            >
              {isPaymentLoading ? <>
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div></> : "Pay"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

function TransitionManageForm() {
  const [value, setValue] = useState(0);
  const [wait, isWait] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [store, dispatch] = useStateValue();

  const [Tstate, setTState] = useState({
    addNew: true,
    web_link: '',
    fb_link: '',
    insta_link: '',
    images: [],
    images_url: [],
    videos: [],
    videos_url: [],
  })

  const [myTransitions, setTransitions] = useState([])

  useEffect(() => {
    if (store.businessId != '' || store.businessId != ' ') {
      // get data with business id
      console.log(store.fullTransitionState)
      setTState(store.fullTransitionState)
    }
  }, [])

  const onChange = e => {
    setTState({
      ...Tstate,
      [e.target.name]: e.target.value
    })
  }

  const handelPhotos = (e) => {
    setValue(0)
    isWait(true)
    var i = 0
    var img_url = Tstate.images_url;
    var old_img = Tstate.images
    while (i < e.target.files.length) {
      old_img.push(e.target.files[i])
      
      let reader = new FileReader();
      reader.onload = (event) => {
        img_url.push(event.target.result)
      };
      reader.readAsDataURL(e.target.files[i]);
      i++
      if (i == e.target.files.length) {
        console.log(old_img, img_url)
        setTState({ ...Tstate, images: old_img, images_url: img_url });
        setTimeout(function () {
          setValue(1)
          isWait(false)
        }, 2000)
      }
    }

    console.log(Tstate)
  }
  const handelVideos = (e) => {
    setValue(0)
    isWait(true)
    var i = 0
    var vid_url = Tstate.videos_url;
    var old_vid = Tstate.videos
    while (i < e.target.files.length) {
      old_vid.push(e.target.files[i])

      let reader = new FileReader();
      reader.onload = (event) => {
        vid_url.push(event.target.result)
      };
      reader.readAsDataURL(e.target.files[i]);
      i++
      if (i == e.target.files.length) {
        console.log(old_vid, vid_url)
        setTState({ ...Tstate, videos: old_vid, videos_url: vid_url });
        setTimeout(function () {
          setValue(1)
          isWait(false)
        }, 2000)
      }
    }
    // setTState({ ...Tstate, videos: e.target.files });
  }

  const uploadDataNow = () => {

    dispatch({
      type: "SET_FULL_TRANSITIONS",
      data: Tstate
    })

    dispatch({
      type: "CHANGE_STEP",
      data: 3
    })
    window.scrollTo(0, 0)
    return

    setUploading(true)
    // uploadBusinessInfo
    const formData = new FormData();
    var userToken = getAuthentication('user');
    formData.append('facebook', Tstate.fb_link)
    formData.append('instagram', Tstate.insta_link)
    formData.append('website', Tstate.web_link)
    formData.append('txt', Tstate.txt)

    Object.values(Tstate.images).forEach(file => {
      formData.append("uploadImages", file);
    });

    Object.values(Tstate.videos).forEach(file => {
      formData.append("uploadImages", file);
    });

    axios.post(`${KEYS.NODE_URL}/api/order/uploadBusinessInfo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then(result => {
        console.log('result data uploaded : ', result)
        toast.success(result.data.message)
        dispatch({
          type: "SET_BUSINESS_ID",
          data: result.data.bInfo._id
        });
        dispatch({
          type: "CHANGE_STEP",
          data: 3
        })
        window.scrollTo(0, 0)
        setUploading(false)
      }).catch(err => {
        console.log('imhere...', err.response)
        setUploading(false)
      })
  }

  const removeVideo = myVideo => {
    var myurls = []
    var myvideos = []
    Tstate.videos_url.forEach((video, index) => {
      if (video != myVideo) {
        myurls.push(video)
      } else {
        Tstate.videos.forEach((vid, ind) => {
          if (index != ind) {
            myvideos.push(vid)
          }
        })
      }
    })
    setTState({
      ...Tstate,
      videos_url: myurls,
      videos: myvideos
    })
  }

  const removeImage = myImage => {
    console.log(myImage);
    var myurls = []
    var myimages = []

    Tstate.images_url.forEach((image, index) => {
      if (image != myImage) {
        myurls.push(image)
      } else {
        console.log(index);
        Tstate.images.forEach((img, ind) => {
          if (index != ind) {
            myimages.push(img)
          }
        })
      }
    })
    console.log(myurls)
    setTState({
      ...Tstate,
      images_url: myurls,
      images: myimages
    })
  }

  return (
    <>
      {false ? "Loading..." :
        (
          <>
            <div className="addTransition">
              <div className="col-12 row align-items-center justify-content-between">
                <h4>Customize Your LED Graphics</h4>

              </div>
              <hr />

              <div className={Tstate.addNew ? "col-12 addNew active" : "col-12 addNew"}>
                <div className="form-outline my-4 col-8">
                  <label htmlFor="" className="form-label">Enter Some Text</label>
                  <input type="text" placeholder="Type Text" className="form-control form-control-lg" name="txt" onChange={onChange} value={Tstate.txt} />
                  <small><b>Note: This Text is Shown at Your Device</b></small>
                </div>
                <div className="form-outline my-4 col-8">
                  <label htmlFor="" className="form-label">Enter Your Website link</label>
                  <input type="text" placeholder="Type Website url" className="form-control form-control-lg" name="web_link" onChange={onChange} value={Tstate.web_link} />
                </div>
                <div className="form-outline my-4 col-8">
                  <label htmlFor="" className="form-label">Enter Facebook Account/page link</label>
                  <input type="text" placeholder="Type FB url" className="form-control form-control-lg" name="fb_link" onChange={onChange} value={Tstate.fb_link} />
                </div>
                <div className="form-outline my-4 col-8">
                  <label htmlFor="" className="form-label">Enter Instagram Account/page link</label>
                  <input type="text" placeholder="Type Instagarm Url" className="form-control form-control-lg" name="insta_link" onChange={onChange} value={Tstate.insta_link} />
                </div>
                <div className="form-outline my-4 col-8">
                  <label htmlFor="" className="form-label">Select Images</label>
                  <input type="file" className="form-control form-control-lg" name="imageInput" onChange={(e) => handelPhotos(e)} multiple />
                </div>

                <div className="img_div">
                  {
                    wait ? "Loading..." :
                      Tstate.images_url.map((image, index) => (
                        <>
                          <div className="img-cover-box">
                            <img src={image} />
                            <div className="crose"><span onClick={() => removeImage(image)}>X</span></div>
                          </div>
                        </>
                      ))
                  }
                </div>
                <div className="form-outline my-4 col-8">
                  <label htmlFor="" className="form-label">Select Videos</label>
                  <input type="file" className="form-control form-control-lg" name="videoInput" onChange={(e) => handelVideos(e)} multiple />
                </div>

                <div className="img_div">
                  {
                    wait ? "Loading..." :
                      Tstate.videos_url.map((video, index) => (
                        <>
                          <div className="img-cover-box video-cover-box">
                            <video width="250">
                              <source src={video} type="video/mp4" />
                            </video>
                            <div className="crose"><span onClick={() => removeVideo(video)}>X</span></div>
                          </div>
                        </>
                      ))
                  }
                </div>


                <div className="pb-4"></div>
                <button className="btn btn-primary mt-4 col-2 float-right" onClick={() => !uploading ? uploadDataNow() : null}>Save & Next</button>
              </div>
            </div>
          </>
        )}

    </>
  )
}

export default Checkout;